import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
// components
import {
  Award,
  Cast,
  Check,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  FileText,
  LogIn,
  LogOut,
  Lock,
  Maximize2,
  Mic,
  MicOff,
  Minimize2,
  Monitor,
  Phone,
  Smile,
  Trash2,
  User,
  Users,
  Video,
  VideoOff,
  Volume2,
  VolumeX,
  XSquare,
  X,
  ArrowDown,
  Calendar,
  MessageCircle,
  ArrowLeft,
} from 'react-feather';
// types
import { IUIIcons } from '@/types';
import ChatIcon from '../../new-icons/Chat';
import PollIcon from '../../new-icons/Poll';
import QuestionIcon from '../../new-icons/Question';
import RaisedHandIcon from '../../new-icons/RaisedHand';
import RightArrowIcon from '../../new-icons/RightArrow';
import FileIcon from '../../new-icons/File';
import People from '../../new-icons/People';
import UserSpeaking from '../../new-icons/UserSpeaking';
import FileList from '../../new-icons/FileList';
import UserSpeakingOutlined from '../../new-icons/UserSpeakingOutlined';
import Message from '../../new-icons/Message';
import Asterisk from '../../new-icons/Asterisk';
import SignalTower from '../../new-icons/SignalTower';
import TimerLine from '../../new-icons/TimerLine';
import Feed from '../../new-icons/Feed';
import KebabMenu from '../../new-icons/KebabMenu';
import GridLayout from '../../new-icons/GridLayout';
import ScreenShareOn from '../../new-icons/ScreenShareOn';
import ScreenShareOff from '../../new-icons/ScreenShareOff';
import VolumeOff from '../../new-icons/VolumeOff';
import VolumeOn from '../../new-icons/VolumeOn';
import BroadcastStart from '../../new-icons/BroadcastStart';
import BroadcastStop from '../../new-icons/BroadcastStop';
import Browser from '../../new-icons/Browser';
import Wifi from '../../new-icons/Wifi';
import Heart from '../../new-icons/Heart';
import Edit from '../../new-icons/Edit';
import SuccessCircleFilled from '../../new-icons/SuccessCircleFilled';
import AlertCircleFilled from '../../new-icons/AlertCircleFilled';
import ImageIcon from '@/components/ui/new-icons/Image';
import VideoRecordingIcon from '@/components/ui/new-icons/VideoRecording';
import PresentationIcon from '@/components/ui/new-icons/Presentation';
import Filter from '../../new-icons/Filter';
import SendIcon from '../../new-icons/Send';
import ExternalLinkLineIcon from '../../new-icons/ExternalLinkLine';
import EndCall from '../../new-icons/EndCall';
import AddCircleFilled from '../../new-icons/AddCircleFilled';
import Globe from '../../new-icons/Globe';
import Compass from '../../new-icons/Compass';
import Extension from '../../new-icons/Extension';
import FeedV2 from '../../new-icons/FeedV2';
import FeedV3 from '../../new-icons/FeedV3';
import Gif from '../../new-icons/Gif';
import CloseCircleFilled from '../../new-icons/CloseCircleFilled';
import MenuMoreVerticalDots from '../../new-icons/MenuMoreVerticalDots';
import CaptionIcon from '../../new-icons/Caption';
import SettingsFilledIcon from '../../new-icons/SettingsFilledIcon';
import {
  BrowserFail,
  BrowserSuccess,
  WifiFail,
  WifiSuccess,
} from '@/components/CompatibilityCheck/icons';
import VirtualBackgroundOff from '../../new-icons/VirtualBackgroundOff';
import VirtualBackgroundOn from '../../new-icons/VirtualBackgroundOn';
import SmileEmoji from '../../new-icons/SmileEmoji';
import { ListUl } from '@styled-icons/boxicons-regular';
import RaisedHandV2 from '../../new-icons/RaisedHandV2';
import { PieChart } from '@styled-icons/boxicons-solid/PieChart';
import {
  Microphone as BoxIconMicrophone,
  MicrophoneOff as BoxIconMicrophoneOff,
  Video as BoxIconVideo,
  VideoOff as BoxIconVideoOff,
} from '@styled-icons/boxicons-solid';

const {
  BOX_ICON_LIST_UL,
  BROADCAST_START,
  BROADCAST_STOP,
  CALENDAR,
  CHAT,
  CHECK,
  COLLAPSE_MENU_2,
  COLLAPSE_MENU,
  DELETE,
  EMOJI,
  EXPAND_MENU_2,
  EXPAND_MENU,
  EXPO,
  FILES,
  FILE_TEXT,
  FULL_SCREEN_MAX,
  FULL_SCREEN_MIN,
  HAND_LOWER,
  HAND_RAISE,
  MIC_OFF,
  MIC,
  POLL,
  QA,
  RAISED_HANDS,
  SHARE_SCREEN_OFF,
  SHARE_SCREEN,
  STREAM_JOIN,
  STREAM_LEAVE,
  USER,
  USERS,
  VIDEO_OFF,
  VIDEO,
  VOLUME_OFF,
  VOLUME_ON,
  RIGHT_ARROW,
  LOCK,
  NEW_VIDEO_ON,
  NEW_VIDEO_OFF,
  NEW_MIC_ON,
  NEW_MIC_OFF,
  CROSS,
  PEOPLE,
  ARROW_DOWN,
  USER_SPEAKING,
  FILE_LIST,
  USER_SPEAKING_OUTLINED,
  MESSAGE,
  ASTERISK,
  SIGNAL_TOWER,
  TIMER_LINE,
  KEBAB_MENU,
  FEATHER_CALENDAR,
  FEATHER_MESSAGE_CIRCLE,
  FEED,
  FEATHER_ARROW_LEFT,
  SCREEN_SHARE_ON,
  NEW_SCREEN_SHARE_OFF,
  NEW_VOLUME_ON,
  NEW_VOLUME_OFF,
  NEW_BROADCAST_START,
  NEW_BROADCAST_STOP,
  BROWSER,
  WIFI,
  SUCCESS_CIRCLE_FILLED,
  ALERT_CIRCLE_FILLED,
  EDIT_FILLED,
  HEART_FILLED,
  FILTER_FILLED,
  GRID_LAYOUT,
  IMAGE,
  VIDEO_RECORDING,
  PRESENTATION,
  SEND,
  EXTERNAL_LINK_LINE,
  BOOTH_VOLUME_OFF,
  BOOTH_VOLUME_ON,
  END_CALL,
  PLUS_CIRCLE_FILLED,
  TICKET,
  GLOBE,
  COMPASS,
  EXTENSION,
  FEEDV2,
  FEEDV3,
  GIF,
  CLOSE_CIRCLE_FILLED,
  MORE,
  BROWSER_SUCCESS,
  BROWSER_FAIL,
  WIFI_SUCCESS,
  WIFI_FAIL,
  INTRO_VIDEO_VOLUME_ON,
  INTRO_VIDEO_VOLUME_OFF,
  VIRTUAL_BACKGROUND_OFF,
  VIRTUAL_BACKGROUND_ON,
  SMILE_EMOJI,
  RAISED_HAND_V2,
  SURVEYS,
  CLOSED_CAPTION,
  SETTINGS,
} = IUIIcons;

export const getIcon = (
  iconType,
  iconSize,
  iconColor,
  parentStyles,
  iconStyle,
) => {
  const strokeClassName = styles[`stroke-${iconColor}`];
  const fillClassName = styles[`fill-${iconColor}`];

  const ICON_MAP = {
    [BROADCAST_START]: <Cast className={strokeClassName} size={iconSize} />,
    [BROADCAST_STOP]: (
      <Phone
        className={classnames(parentStyles.endCallIcon, strokeClassName)}
        color={iconColor}
        size={iconSize}
      />
    ),
    [CHAT]: (
      <ChatIcon
        className={classnames(styles.chatIcon, fillClassName)}
        color={iconColor}
        size={iconSize}
      />
    ),
    [MORE]: (
      <MenuMoreVerticalDots
        className={classnames(styles.chatIcon, fillClassName)}
        color={iconColor}
        size={iconSize}
      />
    ),
    [CALENDAR]: (
      <Calendar className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [RIGHT_ARROW]: (
      <RightArrowIcon
        className={classnames(styles.chatIcon, fillClassName)}
        color={iconColor}
        size={iconSize}
      />
    ),
    [CHECK]: (
      <Check className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [COLLAPSE_MENU_2]: (
      <ChevronsRight
        className={strokeClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [COLLAPSE_MENU]: (
      <ChevronRight
        className={strokeClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [DELETE]: (
      <Trash2 className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [EMOJI]: (
      <Smile className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [EXPAND_MENU_2]: (
      <ChevronsLeft
        className={strokeClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [EXPAND_MENU]: (
      <ChevronLeft
        className={strokeClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [EXPO]: (
      <Award className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [FILE_TEXT]: (
      <FileText
        className={classnames(fillClassName, styles['stroke-neutral-base-2'])}
        color={iconColor}
        size={iconSize}
      />
    ),
    [FILES]: (
      <FileIcon className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [FULL_SCREEN_MAX]: (
      <Maximize2
        className={strokeClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [FULL_SCREEN_MIN]: (
      <Minimize2
        className={strokeClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [HAND_LOWER]: (
      <RaisedHandIcon
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [HAND_RAISE]: (
      <RaisedHandIcon
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [MIC_OFF]: (
      <MicOff className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [MIC]: (
      <Mic className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [POLL]: (
      <PollIcon className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [QA]: (
      <QuestionIcon
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [RAISED_HANDS]: (
      <RaisedHandIcon
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [SHARE_SCREEN_OFF]: (
      <XSquare className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [SHARE_SCREEN]: (
      <Monitor className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [STREAM_JOIN]: (
      <LogIn className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [STREAM_LEAVE]: (
      <LogOut className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [USER]: (
      <User className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [USERS]: (
      <Users className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [VIDEO_OFF]: (
      <VideoOff className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [VIDEO]: (
      <Video className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [VOLUME_OFF]: (
      <VolumeX className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [VOLUME_ON]: (
      <Volume2 className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [LOCK]: (
      <Lock className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [NEW_VIDEO_ON]: (
      <BoxIconVideo
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [NEW_VIDEO_OFF]: (
      <BoxIconVideoOff
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [NEW_MIC_ON]: (
      <BoxIconMicrophone
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [NEW_MIC_OFF]: (
      <BoxIconMicrophoneOff
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [CROSS]: (
      <X
        style={iconStyle}
        className={strokeClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [PEOPLE]: (
      <People className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [ARROW_DOWN]: (
      <ArrowDown
        className={strokeClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [USER_SPEAKING]: (
      <UserSpeaking
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [FILE_LIST]: (
      <FileList className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [USER_SPEAKING_OUTLINED]: (
      <UserSpeakingOutlined
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [MESSAGE]: (
      <Message className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [ASTERISK]: (
      <Asterisk className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [SIGNAL_TOWER]: (
      <SignalTower
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [BOX_ICON_LIST_UL]: (
      <ListUl className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [TIMER_LINE]: (
      <TimerLine className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [KEBAB_MENU]: (
      <KebabMenu className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [FEATHER_CALENDAR]: (
      <Calendar className={strokeClassName} color={iconColor} size={iconSize} />
    ),
    [FEATHER_MESSAGE_CIRCLE]: (
      <MessageCircle
        className={strokeClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [FEED]: (
      <Feed className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [FEATHER_ARROW_LEFT]: (
      <ArrowLeft
        className={strokeClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [NEW_SCREEN_SHARE_OFF]: (
      <ScreenShareOff
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [SCREEN_SHARE_ON]: (
      <ScreenShareOn
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [NEW_VOLUME_OFF]: (
      <VolumeOff className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [NEW_VOLUME_ON]: (
      <VolumeOn className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [BOOTH_VOLUME_OFF]: (
      <VolumeOff className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [BOOTH_VOLUME_ON]: (
      <VolumeOn className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [NEW_BROADCAST_START]: (
      <BroadcastStart
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [NEW_BROADCAST_STOP]: (
      <BroadcastStop
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [GRID_LAYOUT]: (
      <GridLayout className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [BROWSER]: <Browser className={fillClassName} size={iconSize} />,
    [WIFI]: <Wifi className={fillClassName} size={iconSize} />,
    [SUCCESS_CIRCLE_FILLED]: <SuccessCircleFilled />,
    [ALERT_CIRCLE_FILLED]: <AlertCircleFilled />,
    [EDIT_FILLED]: (
      <Edit className={fillClassName} size={iconSize} color={iconColor} />
    ),
    [HEART_FILLED]: (
      <Heart className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [FILTER_FILLED]: (
      <Filter className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [IMAGE]: <ImageIcon color={iconColor} size={iconSize} />,
    [VIDEO_RECORDING]: <VideoRecordingIcon color={iconColor} size={iconSize} />,
    [PRESENTATION]: <PresentationIcon color={iconColor} size={iconSize} />,
    [SEND]: <SendIcon />,
    [EXTERNAL_LINK_LINE]: (
      <ExternalLinkLineIcon color={iconColor} size={iconSize} />
    ),
    [END_CALL]: (
      <EndCall className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [PLUS_CIRCLE_FILLED]: <AddCircleFilled />,
    [GLOBE]: (
      <Globe className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [COMPASS]: (
      <Compass className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [EXTENSION]: (
      <Extension className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [FEEDV2]: (
      <FeedV2 className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [FEEDV3]: (
      <FeedV3 className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [GIF]: <Gif className={fillClassName} color={iconColor} size={iconSize} />,
    [CLOSE_CIRCLE_FILLED]: (
      <CloseCircleFilled color={iconColor} size={iconSize} />
    ),
    [BROWSER_SUCCESS]: <BrowserSuccess />,
    [BROWSER_FAIL]: <BrowserFail />,
    [WIFI_SUCCESS]: <WifiSuccess />,
    [WIFI_FAIL]: <WifiFail />,
    [INTRO_VIDEO_VOLUME_OFF]: (
      <VolumeOff className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [INTRO_VIDEO_VOLUME_ON]: (
      <VolumeOn className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [VIRTUAL_BACKGROUND_OFF]: <VirtualBackgroundOff />,
    [VIRTUAL_BACKGROUND_ON]: <VirtualBackgroundOn />,
    [SMILE_EMOJI]: (
      <SmileEmoji className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [RAISED_HAND_V2]: (
      <RaisedHandV2
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [SURVEYS]: (
      <PieChart className={fillClassName} color={iconColor} size={iconSize} />
    ),
    [CLOSED_CAPTION]: (
      <CaptionIcon
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
    [SETTINGS]: (
      <SettingsFilledIcon
        className={fillClassName}
        color={iconColor}
        size={iconSize}
      />
    ),
  };

  return ICON_MAP[iconType];
};

export const getTooltipText = iconType => {
  const TOOLTIP_MAP = {
    [BROADCAST_START]: 'Start Public Broadcast',
    [BROADCAST_STOP]: 'End Broadcast',
    [CHAT]: 'View Chat',
    [CHECK]: 'Mark Answered',
    // [COLLAPSE_MENU_2]: 'Collapse Menu',
    [EMOJI]: 'Send Emoji',
    // [EXPAND_MENU_2]: 'Expand Menu',
    [FILES]: 'View Files',
    [HAND_LOWER]: 'Lower Hand',
    [HAND_RAISE]: 'Raise Hand',
    [MIC]: 'Mute',
    [MIC_OFF]: 'Unmute',
    [POLL]: 'View Polls',
    [QA]: 'View Q&A',
    [SHARE_SCREEN]: 'Share Screen',
    [SHARE_SCREEN_OFF]: 'Stop Share Screen',
    [STREAM_JOIN]: 'Join Stream',
    [STREAM_LEAVE]: 'Remove User',
    [RAISED_HANDS]: 'View Raised Hand Requests',
    [VIDEO]: 'Hide Video',
    [VIDEO_OFF]: 'Show Video',
    [NEW_BROADCAST_START]: 'Start Public Broadcast',
    [NEW_BROADCAST_STOP]: 'End Broadcast',
    [NEW_MIC_ON]: 'Mute',
    [NEW_MIC_OFF]: 'Unmute',
    [NEW_VIDEO_ON]: 'Hide Video',
    [NEW_VIDEO_OFF]: 'Show Video',
    [SCREEN_SHARE_ON]: 'Stop Screen Share',
    [NEW_SCREEN_SHARE_OFF]: 'Screen Share',
    [NEW_VOLUME_ON]: 'Mute Stage',
    [NEW_VOLUME_OFF]: 'Unmute Stage',
    [EDIT_FILLED]: 'Add your <br /> basic information',
    [HEART_FILLED]: 'Add interests to find the right profile for you',
    [FILTER_FILLED]: 'Add filters to help us pick relevant profiles',
    [SEND]: 'Offensive words are not allowed. Please try again',
    [EXTERNAL_LINK_LINE]: 'View Live Expo',
    [BOOTH_VOLUME_ON]: 'Mute Expo',
    [BOOTH_VOLUME_OFF]: 'Unmute Expo',
    [TICKET]: 'Group derived from ticket cannot be removed',
    [VIRTUAL_BACKGROUND_ON]: 'Turn virtual background off',
    [VIRTUAL_BACKGROUND_OFF]: 'Turn virtual background on',
    [SURVEYS]: 'View Surveys',
    [CLOSED_CAPTION]: 'Toggle Closed captions'
  };
  return TOOLTIP_MAP[iconType];
};

export { IUIIcons as IIconButtonTypes };
