import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
// styles + types
import styles from './styles.module.scss';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';
import ReactTooltip from 'react-tooltip';
import { keyboardAccessible } from '@/utils/keyboard accessible';

const CheckBoxField = props => {
  const {
    labelStyles,
    error,
    label,
    onChange,
    onBlur,
    placeholder,
    readOnly,
    value,
    classNames = {},
    disabled = false,
    tooltip,
    sizeInPx = 15,
    strict = false,
    showLabel = true,
    hoverToolTip,
    isInputAtEnd = false,
    dataTestId,
    isKeyboardNavigable
  } = props;

  // const [inputID] = useState(`CB-${uuidv4()}`);
  const [isChecked, setIsChecked] = useState(null);
  let reactToolTipData = {};

  useEffect(() => {
    setIsChecked(!!value);
  }, [value]);

  const toggleChecked = event => {
    if (disabled) {
      return;
    }
    // Input event is raised even thought the input is hidden.
    if (event.target.nodeName !== 'INPUT') {
      onChange && onChange(!isChecked);
      !strict && setIsChecked(!isChecked);
    }
    event.stopPropagation();
  };

  if (hoverToolTip) {
    reactToolTipData = {
      'data-tip': true,
      'data-for': `tooltip_checkbox_${label}`,
    };
  }

  return (
    <div
      className={classnames(
        styles.container,
        classNames.pointerType,
        classNames.mainContainer,
        {
          'elementAsButton': isKeyboardNavigable
        }
      )}
      {
        ...(
          isKeyboardNavigable ?  {
            tabIndex: 0,
            onKeyDown: (e: any) => keyboardAccessible(e, () => toggleChecked(e))
          }:{ }
        )
      }
      onClick={toggleChecked}
      
    >
      {!isInputAtEnd && (
        <div
          className={classnames(
            styles.checkBoxContainer,
            classNames.checkBoxContainer,
          )}
          style={{ height: `${sizeInPx}px`, width: `${sizeInPx}px` }}
        >
          <label
            className={classnames(
              styles.checkBoxField,
              classNames.checkBoxField,
            )}
          >
            <input
              type="checkbox"
              data-testid={dataTestId}
              checked={isChecked}
            />
            <span
              className={classnames(
                styles.checkmark,
                classNames.checkmark,
                classNames.pointerType,
                {
                  [styles.disabledCheckMark]: disabled,
                },
              )}
            ></span>
          </label>
        </div>
      )}
      {showLabel && (
        <div
          className={classnames(styles.labelText, classNames.labelText, {
            [styles[labelStyles]]: !!labelStyles,
          })}
          {...reactToolTipData}
        >
          {label}
        </div>
      )}
      {isInputAtEnd && (
        <div
          className={classnames(
            styles.checkBoxContainer,
            classNames.checkBoxContainer,
          )}
          style={{ height: `${sizeInPx}px`, width: `${sizeInPx}px` }}
        >
          <label
            className={classnames(
              styles.checkBoxField,
              classNames.checkBoxField,
            )}
          >
            <input
              type="checkbox"
              data-testid={dataTestId}
              checked={isChecked}
            />
            <span
              className={classnames(
                styles.checkmark,
                classNames.checkmark,
                classNames.pointerType,
              )}
            ></span>
          </label>
        </div>
      )}
      {label && label.length && tooltip ? (
        <TooltipDiv
          dataFor={`tooltip_checkbox_${label}`}
          tooltipText={tooltip}
        />
      ) : null}
      {label && label.length > 50 && hoverToolTip && (
        <ReactTooltip
          className={styles.tooltipContainer}
          id={`tooltip_checkbox_${label}`}
          place="top"
          effect="solid"
          backgroundColor="#000"
        >
          {label}
        </ReactTooltip>
      )}
      {error && <FormValidationErrorTooltip value={error} />}
    </div>
  );
};

export default React.memo(CheckBoxField);
