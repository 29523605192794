import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
// styles + types
import styles from './styles.module.scss';
import FormValidationErrorTooltip from '../../FormValidationErrorTooltip';
import TooltipDiv from '@/components/ui/icons/TooltipDiv';
import { keyboardAccessible } from '@/utils/keyboard accessible';

const ToggleField = props => {
  const {
    labelStyles,
    error,
    label,
    onChange,
    onBlur,
    placeholder,
    readOnly = false,
    value,
    classNames = {},
    disabled = false,
    tooltip,
    widthInPx = 45,
    heightInPx = 20,
    customToggleContainerStyles,
    isKeyboardNavigable = false
  } = props;

  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    setIsChecked(!!value);
  }, [value]);

  const toggleChecked = event => {
    if (disabled || readOnly) {
      return;
    }
    // Input event is raised even thought the input is hidden.
    if (event.target.nodeName !== 'INPUT') {
      onChange && onChange(!isChecked);
      setIsChecked(!isChecked);
      event.stopPropagation();
    }
  };

  return (
    <div
      {...(isKeyboardNavigable ? {
          tabIndex: 0,
          onKeyDown: (e: any) => keyboardAccessible(e, () => toggleChecked(e)),
        }: {
          "aria-hidden":true
        }
      )}
      className={classnames(styles.container, {
        [styles.disabled]: disabled,
        'elementAsButton': isKeyboardNavigable,
      })}
      onClick={toggleChecked}
    >
      <div
        className={classnames(styles.toggleContainer, {
          [styles[customToggleContainerStyles]]: !!customToggleContainerStyles,
        })}
        style={{ height: `${heightInPx}px`, width: `${widthInPx}px` }}
      >
        <label className={styles.switch}>
          <input type="checkbox" checked={isChecked} readOnly={readOnly} />
          <span
            className={classnames(styles.slider, {
              [styles.disabled]: disabled,
            })}
          ></span>
        </label>
      </div>
      {label && (
        <div
          className={classnames(styles.labelText, classNames.labelText, {
            [styles[labelStyles]]: !!labelStyles,
          })}
        >
          {label}
        </div>
      )}
      {label && label.length && tooltip ? (
        <TooltipDiv
          dataFor={`tooltip_checkbox_${label}`}
          tooltipText={tooltip}
        />
      ) : null}
      {error && <FormValidationErrorTooltip value={error} />}
    </div>
  );
};

export default React.memo(ToggleField);
